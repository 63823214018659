.ant-table table { 
    font-size: 14px; 
}

.ant-side-custom {
    position: fixed !important;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #003A00 !important;
}

.ant-menu-item {
    background-color: #003A00 !important;
}

.ant-menu-item-active {
    background-color: #00A000 !important;
}

.ant-menu-dark {
    background-color: #003A00 !important;
}