@tailwind base;
@tailwind components;
@tailwind utilities;

@media(max-width: 576px) { 
  .ant-picker-panels { 
    flex-direction: column;
  } 
}

.green-app {
  background-color: #003A00;
}